/* eslint-disable */
import { renderSlim } from '@aspectus/vue-utils'

import {
  urlGetter,
} from '@utils/friendly'

const same = x => x
export default {
  name: 'router-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    baseUrl: {},
    getData: {},
    needUpdateUrlOnMounted: {
      default: false,
    },
  },

  data() {
    return {
      parameters: this.initial,
      friendlyUrl: {
        filters: {},
      },
      isPopstate: false,
      isMounted: true,
    }
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.parameters = this.from({
        ...this.initial,
      })
      this.isPopstate = true
      this.$nextTick(() => {
        this.getData()
      })
      const timeout = 400
      setTimeout(() => {
        this.isPopstate = false
      }, timeout)
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.parameters = this.from({
          ...this.initial,
        })
      },
    },
  },

  methods: {
    updateUrl(parameters) {
      if (this.isPopstate) return

      const query = this.to(parameters)

      const zero = 0

      this.friendlyUrl.filters = query.filters

      const keys = Object.keys(this.friendlyUrl.filters)

      for (let x = 0; x <= keys.length; x++) {
        const f = this.friendlyUrl.filters[keys[x]]

        if (f !== undefined) {
          if (zero === f.length) {
            delete this.friendlyUrl.filters[keys[x]]
          }
        }
      }
      const order = {
        path: [
          'prefix',
          'filters',
        ],
        query: [
          'pagination_offset',
        ],
      }

      const shift = 1
      const currentPage = Math.floor(parameters.offset / parameters.limit) + shift
      const page = currentPage > shift ? currentPage : null
      const fullUrl = urlGetter([this.baseUrl], order, { ...parameters, page })
      const queryUrl = fullUrl.replace(this.baseUrl, '')
      /**
       * Friendly url used in the request to backend
       * But with friendly url we have troubles with router
       * and here we are changing friendly url to query params
       */
      const [commonParams, paginationParams] = queryUrl.split('?')
      const arr = [commonParams, paginationParams].filter(el => el)
      const joinedUrl = arr.join(';').replace(/;/g, '&')
      const preparedUrl = joinedUrl ? `${this.baseUrl}?${joinedUrl}`.replace(/\?\?/g, '?') : `${this.baseUrl}/`
      const decodedUrl = decodeURIComponent(preparedUrl)
      const prettyUrl = decodedUrl.replace(/\/$/, "")

      history.pushState({ url: prettyUrl }, '', `${prettyUrl}`)

      document.dispatchEvent(new CustomEvent('queryChanged'))
    },

    changeParameters(value) {
      if (this.isMounted && !this.needUpdateUrlOnMounted) {
        this.isMounted = false
      } else {
        this.$set(this, 'parameters', value)

        this.updateUrl(this.parameters)
      }

      this.$emit('update:parameters', value)
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      changeParameters: this.changeParameters,
      updateUrl: this.updateUrl,
    }), h, 'tag')
  },
}
