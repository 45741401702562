<template lang='pug'>
  include /mixins
  +b.i-catalog.--variant_default
    +b.i-panel.--space_sm
      +b-context('i-catalog')
        slot(name="heading")
        catalog-sidebar(
          :filters="filters"
          @update:filters="changeFiltersLocally"
          :pagination="pagination",
          :attributesResource='attributesResource',
          :additionalResource="additionalResource"
          :label='label'
          :filterClass="filterClass"
          :filterVariant="filterVariant"
          :needSearch="needSearch"
        )
        +e.content.relative
          +b.i-preloader.--variant_2.--absolute(:class="{'is-active': loading}")
            +e.item.--size_md
          slot
    ui-limit-pagination(
      :pagination='pagination'
      @input='handlePaginate'
    )
</template>

<script>

import CatalogSidebar from './Sidebar'

export default {
  components: { CatalogSidebar },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'label',
    'filterClass',
    'filterVariant',
    'needSearch',
    'additionalResource',
  ],

  computed: {
    back() {
      return `${window.location.pathname}${window.location.search}`
    },
  },

  created() {
    this.getData()
  },

  methods: {
    getData(sortParams) {
      if (sortParams && this.parameters.filters.sort) {
        this.parameters.filters.sort = sortParams.sort
      } else if (sortParams) {
        if (sortParams.sort) {
          this.parameters.filters = { ...this.parameters.filters, ...sortParams }
        } else {
          delete this.parameters.filters.sort
        }
      }
      this.changeFilters(this.parameters)
      // this.receive(this.parameters)
    },

    changeFiltersLocally(params) {
      const data = { ...params }

      data.filters.sort = this.parameters.filters.sort

      this.changeFilters(data)
    },

    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
