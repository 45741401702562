import { curry } from 'ramda'
import qs from 'qs'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  jsonTransformer,
} from '@resource/transformer'

const filtersGetterBase = makeTemplateGetter('{;filters*}');
const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null

const queryGetter = ({ query }) => {
  const dynamicQuery = qs.stringify(query, {
    addQueryPrefix: true,
  })

  return decodeURIComponent(dynamicQuery)
}

const partGetter = (name, prefix) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`);
  return parameters => {
    const result = base(parameters);
    return result === prefix ? null : result;
  };
};

const pageGetter = partGetter('page', 'page');
const categoryGetter = makeTemplateGetter('{label}');
const getParametersGetter = makeTemplateGetter('{?limit,offset}');

const orderMap = {
  label: categoryGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  page: pageGetter,
  query: queryGetter,
}

const initOrder = {
  path: [
    'prefix',
    'filters',
    'pagination_offset',
  ],
  query: [
    'query',
  ],
}

export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
  const base = { prefix, postfix }
  const pathParts = path.reduce((acc, x, i) => {
    acc[i] = orderMap[x] ? orderMap[x](p) : base[x]
    return acc
  }, Array.from({ length: path.length }, () => null))
  const queryParams = query.reduce((acc, x) => {
    acc.push(orderMap[x] ? orderMap[x](p) : base[x])
    return acc
  }, [])
  const filteredPath = pathParts.filter(x => x).join('/')
  const fullReplaced = filteredPath.replace(/\/\//img, '/')
  const searchPath = queryParams.join('')
  const prettyUrl = decodeURIComponent(fullReplaced)
  const finalSearchUrl = `${prettyUrl}${searchPath}`.replace(/\/\//img, '/')

  return searchPath ? finalSearchUrl : prettyUrl
})

export const friendlyUrlGenerator = (url, resource, order = initOrder) => resource
  .url(urlGetter(url, order))
  .transform(jsonTransformer)
